import React, {useState, useEffect} from 'react';
import AuthService from "../services/auth.service";
import {Redirect, useHistory} from "react-router-dom";
import {Formik, Form, Field, ErrorMessage} from 'formik';
import FacebookIcon from '@mui/icons-material/Facebook';
import * as Yup from "yup";

const LoginPage = ({setCurrentUser}) => {
    const [showErrorMessage, setShowErrorMessage] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [redirect, setRedirect] = useState(null);
    const history = useHistory();

    useEffect(() => {
        const currentUser = AuthService.getCurrentUser()?.id;
        if (currentUser) setRedirect("/");
    }, []);

    if (redirect) {
        return <Redirect to="/projects"/>
    }

    const handleLogin = (data) => {
        AuthService.login(data.username, data.password)
            .then((resp) => {
                setCurrentUser(resp);
                history.push("/projects");
            })
            .catch(error => {
                setShowErrorMessage(true);
                setErrorMessage(error.response.data.message);
            });
    }

    const validationSchema = Yup.object().shape({
        username: Yup.string()
            .required('Це поле не може бути порожнім'),
        password: Yup.string()
            .required('Це поле не може бути порожнім'),
    });

    return (
        <div className="row mb-4">
            <div className="col-12 col-lg-4 m-auto">
                <h1 className='title text-center mb-4'>Авторизація</h1>
                <div className="block">
                    <div className="card-form">
                        <Formik
                            initialValues={{
                                username: '',
                                password: '',
                            }}
                            validationSchema={validationSchema}
                            onSubmit={handleLogin}
                        >
                            {({handleSubmit}) => (
                                <Form>
                                    <div className="form-group">
                                        <label htmlFor="username">Ім'я</label>
                                        <Field type='text' name='username' className="form-control"/>
                                        <ErrorMessage name='username'>
                                            {
                                                msg => <div className='error'>{msg}</div>
                                            }
                                        </ErrorMessage>
                                    </div>

                                    <div className="form-group">
                                        <label htmlFor="password">Пароль</label>
                                        <Field type='password' name='password' className="form-control"/>
                                        <ErrorMessage name='password'>
                                            {
                                                msg => <div className='error'>{msg}</div>
                                            }
                                        </ErrorMessage>
                                    </div>

                                    <div className="form-group center">
                                        <button className="btn">
                                            <span>Увійти</span>
                                        </button>
                                    </div>

                                    {
                                        showErrorMessage ?
                                            <div className='error errorMessage'>{errorMessage}</div> : null
                                    }
                                </Form>
                            )}
                        </Formik>

                        <div className="form-group center">
                            <a href='/register' className="mini-btn">
                                Зареєструватися
                            </a>

                            <div><br/><br/>--- або ---<br/><br/></div>

                            <div>
                                <a className="btn" href="https://api.vzhyk.com/social-auth/facebook">
                                    <span><FacebookIcon /> &nbsp;&nbsp;Вхід за допомогою Facebook</span>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default LoginPage;
