import React, {useState, useEffect} from 'react';
import {
    GiSandsOfTime,
    GoHome,
    IoMenu,
    BsSearch,
    FiPlus,
    VscAccount,
    BiMailSend,
    BsLifePreserver,
    BsHurricane,
    FaCheck,
    FaCheckSquare,
    FaCheckDouble,
    FaSplotch,
    GiBatteryPackAlt,
    GrHadoop,
    BsUiRadiosGrid, BsUiChecks, BsUiChecksGrid, ImRocket, GoLightBulb, VscMegaphone, IoMdTrendingUp, IoMdSwap
} from "react-icons/all";
import {useHistory} from "react-router-dom";
import PropTypes from "prop-types";

const Menu = ({notifications, currentUser}) => {
    const history = useHistory();
    const [items, setItems] = useState([]);
    const [soundMyNotifications, setSoundMyNotifications] = useState(false);
    const [soundProgressNotifications, setSoundProgressNotifications] = useState(false);
    const [soundOpenNotifications, setSoundOpenNotifications] = useState(false);
    const sound = new Audio("./assets/notification.mp3");

    useEffect(() => {
        setItems([
            {
                name: 'Знайти завдання',
                slug: '/projects',
                svg: <BsSearch/>,
                auth: true,
                badge: false,
                notification: 'open',
            },
            {
                name: 'В роботі',
                slug: '/progress',
                svg: <GiSandsOfTime/>,
                auth: true,
                badge: false,
                notification: 'progress',
            },
            {
                name: 'Мої завдання',
                slug: '/tasks',
                svg: <GoHome/>,
                auth: true,
                badge: false,
                notification: 'my',
            },
            {
                name: 'Створити',
                slug: '/create',
                svg: <FiPlus/>,
                auth: true,
                badge: false,
                notification: false,
            },
            {
                name: 'Баланс',
                slug: '/balance',
                svg: false,
                text: currentUser && currentUser.balance ? currentUser.balance + '₴' : '0₴',
                auth: true,
                badge: true,
                badgeText: currentUser && currentUser.pending_balance ? currentUser.pending_balance + '₴' : '0₴',
                notification: false,
            },
            {
                name: 'Мій профіль',
                slug: '/profile',
                svg: <VscAccount/>,
                src: currentUser?.profile?.photo ?? false,
                auth: true,
                badge: true,
                badgeText: currentUser && currentUser.rating ? currentUser.rating + "%" : '0%',
                notification: false,
            }
        ]);

        items.map((item, index) => {
            if (item.slug === history.location.pathname) {
                items[index].active = true;
            }
        });

        if (notifications['open'].exists && !soundOpenNotifications) {
            sound.play();
            setSoundOpenNotifications(true);
        }

        if (!notifications['open'].exists) {
            setSoundMyNotifications(false);
        }

        if (notifications['my'].exists && !soundMyNotifications) {
            sound.play();
            setSoundMyNotifications(true);
        }

        if (!notifications['my'].exists) {
            setSoundMyNotifications(false);
        }

        if (notifications['progress'].exists && !soundProgressNotifications) {
            sound.play();
            setSoundProgressNotifications(true);
        }

        if (!notifications['progress'].exists) {
            setSoundProgressNotifications(false);
        }
    }, [currentUser, notifications]);

    return (
        <div>
            <nav className="navbar navbar-expand-lg fixed-top">
                <div className="container">
                    <a className="brand" href="/">
                        <img src="/logo-cropped.png" alt="ВЖИК. Саме так, це наш логотип. Можливо, в майбутньому намалюємо щось оригінальніше. :)" />
                    </a>
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse"
                            data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
                            aria-expanded="false" aria-label="Toggle navigation">
                        <IoMenu/>
                    </button>
                    <div className="collapse navbar-collapse justify-content-end" id="navbarSupportedContent">
                        <ul className="navbar-nav mb-2 mb-lg-0">
                            {items && items.map((item, key) => (currentUser?.id || (!currentUser && !item.auth)) &&
                                <li className="nav-item position-relative menu-item-additional" key={key}>
                                    <a className={'nav-link menu-item ' + (item.active ? 'active' : '')}
                                       href={item.slug}>
                                    <span className="cover">
                                        {!item.src && item.svg && item.svg}
                                        {!item.svg && !item.src &&
                                            <span className="reating">{item.text}</span>}
                                        {item.src &&
                                            <img className="avatar" src={item.src} alt=""/>}
                                    </span>
                                        <span>{item.name}</span>

                                        {item.notification &&
                                            notifications[item.notification].exists && <span className="badge">
                                                 <BiMailSend/>
                                            </span>
                                        }
                                        {item.badge &&
                                            <span className="badge">
                                            {item.badgeText}
                                        </span>
                                        }
                                    </a>
                                </li>
                            )}
                        </ul>
                    </div>
                </div>
            </nav>
        </div>);
}

Menu.propTypes = {
    notification: PropTypes.bool,
    currentUser: PropTypes.object,
};

export default Menu;
