import React, {useState, useEffect} from 'react';
import {Redirect, useHistory} from "react-router-dom";
import AuthService from "../services/auth.service";
import {LiqPayPay} from "react-liqpay";
import {TextField} from "@mui/material";

const BalancePage = () => {
    const [currentUser, setCurrentUser] = useState({username: ""});
    const [userReady, setUserReady] = useState(false);
    const [redirect, setRedirect] = useState(null);
    const [amount, setAmount] = useState(100);
    const API_URL = process.env.REACT_APP_API_URL;

    const payInfo = {
        amount: amount,
        currency: 'грн.',
        title: 'Оплатити'
    }

    const handleSliderChange = (event, newValue) => {
        setAmount(newValue);
    };

    const handleInputChange = (event) => {
        setAmount(event.target.value);
    };

    const ButtonComponent = () => (
        <button className='btn' disabled={amount < 1}>
            {`${payInfo.title} ${payInfo.amount} ${payInfo.currency}`}
        </button>
    )

    useEffect(() => {
        const currentUser = AuthService.getCurrentUser();

        if (!currentUser) setRedirect("/login");

        setCurrentUser(currentUser);
        setUserReady(currentUser);
    }, []);

    if (redirect) {
        return <Redirect to={redirect}/>
    }

    return (
        <div>
            <div className='row'>
                <div className="col-12 col-lg-9 m-auto mb-5">
                    <h1 className="title">
                        Баланс
                    </h1>

                    <div className="card-form">
                        {(userReady) ?
                            <div>
                                <div className="form-group">
                                    <strong>Ваш баланс: </strong>
                                    {currentUser.balance} грн.
                                </div>

                                <form method="POST" action={API_URL + 'payment-form'}>
                                    <div className="row align-items-center mb-4">
                                        <div className="col-12 col-lg-6">
                                            <TextField
                                                id="standard-number"
                                                type="number"
                                                name="amount"
                                                value={amount}
                                                onChange={(event) => handleInputChange(event)}
                                                inputProps={{
                                                    step: 1,
                                                    min: 10,
                                                }}
                                                className="w-100"
                                            />

                                            <input type="hidden" name="uid" value={currentUser.id} />

                                            <button style={{ marginTop: "10px" }} className="btn btn-block">Оплатити</button>
                                        </div>
                                    </div>
                                </form>
                            </div> : null}
                    </div>

                    <h1 className="title">
                        <br />
                        Вивід грошей
                    </h1>

                    <span>
                        Для виводу грошей, будь-ласка, напишіть в чат підтримки.
                        Ми додамо форму замовлення виводу сюди найближчим часом.
                        Поки вивід грошей не працює в автоматичному режимі в цілях уникнення шахрайств, оскільки сервіс поки працює в тестовому режимі та випрявляємо помилки. Дякуємо за розуміння!<br /><br />
                        Мінімальна сума виводу - 50 грн. Комісія сервісу - 15% (податки, розробка сервісу, тощо).<br /><br />
                        10% з доходу сервісу відправляємо на підтримку ЗСУ або благодійні фонди. Ми вирішили дотримуватись цієї політики протягом всього часу існування даного сервісу. Чекайте звітів! :)
                    </span>
                </div>
            </div>
        </div>
    );
}

export default BalancePage;
