import React, {useState, useEffect} from 'react';
import {Redirect} from "react-router-dom";
import AuthService from "../services/auth.service";
import UserService from "../services/user.service";

const FinishRegistrationPage = () => {
    const [currentUser, setCurrentUser] = useState({username: ""});
    const [userReady, setUserReady] = useState(false);
    const [btnDisabled, setBtnDisabled] = useState(false);
    const [redirect, setRedirect] = useState(null);
    const [amount, setAmount] = useState(100);

    useEffect(() => {
        const currentUser = AuthService.getCurrentUser();

        if (!currentUser) setRedirect("/login");

        setCurrentUser(currentUser);
        setUserReady(currentUser);
    }, []);

    if (redirect) {
        return <Redirect to={redirect}/>
    }

    return (
        <div>
            <div className='row'>
                <div className="col-12 col-lg-9 m-auto mb-5 text-center">
                    <h1 className="title">
                        Майже готово!
                    </h1>

                    <span>
                        Виберіть основну ціль реєстрації в сервісі "ВЖИК". Ви й надалі зможете користуватись як інтерфейсом замовника, так і інтерфейсом виконавця.
                        Це всього лиш нам допоможе правильно налаштувати інтерфейс для Вас та пріоритезувати відображення того, що вам більше знадобиться в користуванні цим сервісом.
                    </span>

                    <div className="card-form">
                        {(userReady) ?
                            <div>
                                <div className="form-group mt-4">
                                    <strong>Основна ціль:</strong>
                                </div>

                                <button
                                    disabled={btnDisabled}
                                    style={{ marginTop: "10px", marginRight: "10px" }}
                                    className="btn btn-block"
                                    onClick={() => {
                                        if (!btnDisabled) {
                                            UserService.setGoal(1);
                                            setBtnDisabled(true);
                                        }
                                    }}
                                >Я - Замовник</button>

                                <button
                                    disabled={btnDisabled}
                                    style={{ marginTop: "10px" }}
                                    className="btn btn-block"
                                    onClick={() => {
                                        if (!btnDisabled) {
                                            UserService.setGoal(2);
                                            setBtnDisabled(true);
                                        }
                                    }}
                                >Я - Виконавець</button>
                            </div> : null}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default FinishRegistrationPage;
