import React, {useState, useEffect} from 'react';
import PropTypes from "prop-types";
import ProjectService from "../../services/project.service";
import MenuItem from '@mui/material/MenuItem';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Button from "@mui/material/Button";
import DialogTitle from '@mui/material/DialogTitle';
import Select from '@mui/material/Select';
import {FormControl, FormHelperText, InputLabel} from "@mui/material";

const ModalReport = ({modalIsOpen, reportProject, setIsOpen}) => {
    const [report, setReport] = useState(false);
    const [error, setError] = useState(false);

    const handleClose = () => {
        setIsOpen(false);
    }

    const handleReport = () => {
        ProjectService.report(reportProject.id, report)
            .then((res) => {
                setIsOpen(false);
            })
            .catch(error => {
                setError(true);
            });
    }

    const handleChange = (event) => {
        setReport(event.target.value);
    };

    return (
        <Dialog open={modalIsOpen} onClose={handleClose} maxWidth="xs" fullWidth={true}>
            <DialogTitle>Оберіть скаргу</DialogTitle>
            <DialogContent>
                <FormControl fullWidth error={error} className="mt-2">
                    <InputLabel id="label">Вар.</InputLabel>
                    <Select
                        labelId="label"
                        id="label"
                        value={1}
                        label="Age"
                        onChange={handleChange}
                    >
                        <MenuItem value={1}>Спам</MenuItem>
                        <MenuItem value={2}>Протизаконні дії</MenuItem>
                        <MenuItem value={3}>Обман</MenuItem>
                        <MenuItem value={4}>Образливі дії</MenuItem>
                        <MenuItem value={5}>Інше</MenuItem>
                    </Select>
                    {error && <FormHelperText>Ви вже подавали скаргу на цей проект!</FormHelperText>}
                </FormControl>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Закрити</Button>
                <Button onClick={handleReport}>Поскаржитися</Button>
            </DialogActions>
        </Dialog>
    );
}

ModalReport.propTypes = {
    modalIsOpen: PropTypes.bool,
    reportProject: PropTypes.object,
    setIsOpen: PropTypes.func,
};

export default ModalReport;
