import React, {useState, useEffect} from 'react';
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

const ModalConfirm = ({
                          roomCreator,
                          roomProject,
                          open,
                          setOpen,
                          applyExecutor,
                          dispute,
                          payAndClose,
                          returnInProgress,
                          toReview,
                          modalType
                      }) => {
    const [title, setTitle] = useState('');
    const [content, setContent] = useState('');

    useEffect(() => {
        if (modalType === 'choice') {
            setTitle('Ви бажаєте обрати даного виконавця?');
            setContent('Обиравши виконавця кошти в розмірі вартості проекту будуть заморожені.');
        }

        if (modalType === 'dispute') {
            setTitle('Сеперечка');
            setContent('До вашого діалогу буде підключено адміністратора.');
        }

        if (modalType === 'pay') {
            setTitle('Оплатити та закрити проект?');
            setContent('Якщо виконавець виконав усі ваші вимоги, то підтвердіть це і не забувайте залишити відгук.');
        }

        if (modalType === 'return') {
            setTitle('Відновити процес виконання проекту?');
            setContent('...');
        }

        if (modalType === 'review') {
            setTitle('Ви повністю виконали замовлення?');
            setContent('...');
        }
    }, [modalType]);

    const handleClose = () => {
        setOpen(false);
    };

    const handleEnd = () => {
        if (modalType === 'choice') {
            applyExecutor(roomCreator, roomProject);
        }

        if (modalType === 'dispute') {
            dispute(roomProject);
        }

        if (modalType === 'pay') {
            payAndClose(roomProject);
        }

        if (modalType === 'return') {
            returnInProgress(roomProject);
        }

        if (modalType === 'review') {
            toReview(roomProject);
        }

        setOpen(false);
    };

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                {title}
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {content}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Ні</Button>
                <Button
                    onClick={handleEnd}
                    autoFocus
                >
                    Так
                </Button>
            </DialogActions>
        </Dialog>
    );
}

ModalConfirm.propTypes = {
    open: PropTypes.bool,
    setOpen: PropTypes.func,
    applyExecutor: PropTypes.func,
    dispute: PropTypes.func,
    payAndClose: PropTypes.func,
    returnInProgress: PropTypes.func,
    modalType: PropTypes.string,
};

export default ModalConfirm;
