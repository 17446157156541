import axios from 'axios';
import authHeader from './auth-header';
import AuthService from "../services/auth.service";

const API_URL = process.env.REACT_APP_API_URL;

class UserService {
  heartbeat() {
    return axios.get(API_URL + 'heartbeat', { headers: authHeader() });
  }

  me() {
    return axios.get(API_URL + 'me', { headers: authHeader() }).catch((error) => {
      if (error.response.status === 401 && window.location.pathname !== '/login' && window.location.pathname !== '/register') {
        AuthService.logout();
        window.location = '/login';
      }
    });
  }

  update(data) {
    return axios.post(API_URL + "me", data, { headers: authHeader() });
  }

  setGoal(goal) {
    return axios.post(API_URL + "goal", { goal }, { headers: authHeader() }).then(() => {
      if (goal === 1) {
        window.location = '/tasks';
      }

      if (goal === 2) {
        window.location = '/projects';
      }
    });
  }

  updatePhoto(data) {
    return axios.post(API_URL + "me/photo", data, { headers: authHeader() });
  }

  refreshProfileData(currentUser, setCurrentUser) {
    this.me().then((resp) => {
      const userInfo = {
        ...JSON.parse(localStorage.getItem("user")),
        ...resp?.data || []
      };

      localStorage.setItem("user", JSON.stringify(userInfo));

      if (resp.data.goal <= 0 && window.location.pathname !== '/finish-registration') {
        window.location = '/finish-registration';
      }

      setCurrentUser(userInfo);
    });
  }

  getUserComments(id) {
    return axios.get(API_URL + `user/${id}/comments`, { headers: authHeader() });
  }
}

export default new UserService();
