import React, {useState} from 'react';
import AuthService from "../services/auth.service";
import {useHistory} from "react-router-dom";
import {Formik, Form, Field, ErrorMessage} from 'formik';
import * as Yup from "yup";
import FormData from "form-data";
import FacebookIcon from "@mui/icons-material/Facebook";

const RegisterPage = () => {
    const [showErrorMessage, setShowErrorMessage] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const history = useHistory();

    const handleRegister = (e) => {
        let data = new FormData();

        data.append('name', e.name);
        data.append('email', e.email);
        data.append('password', e.password);

        AuthService.register(data)
            .then(() => {
                history.push("/profile");
                window.location.reload();
            })
            .catch(error => {
                setShowErrorMessage(true);
                setErrorMessage(error.response.data.message);
            });
    }

    const validationSchema = Yup.object().shape({
        name: Yup.string()
            .required('Це поле не може бути порожнім'),
        email: Yup.string()
            .email("Enter a valid email")
            .required('Це поле не може бути порожнім'),
        password: Yup.string()
            .min(6, "Пароль повинен мати щонайменше 6 символів")
            .max(25)
            .required('Це поле не може бути порожнім'),
    });

    return (
        <div className="row mb-4">
            <div className="col-12 col-lg-4 m-auto">
                <h1 className='title text-center mb-4'>Реєстрація</h1>
                <div className="block">
                    <div className="card-form">
                        <Formik
                            initialValues={{
                                name: '',
                                email: '',
                                password: '',
                            }}
                            validationSchema={validationSchema}
                            onSubmit={handleRegister}
                        >
                            {({handleSubmit}) => (
                                <Form>
                                    <div className="form-group">
                                        <label htmlFor="name">Ім'я</label>
                                        <Field type='text' name='name' className="form-control"/>
                                        <ErrorMessage name='name'>
                                            {
                                                msg => <div className='error'>{msg}</div>
                                            }
                                        </ErrorMessage>
                                    </div>

                                    <div className="form-group">
                                        <label htmlFor="email">Email</label>
                                        <Field type='email' name='email' className="form-control"/>
                                        <ErrorMessage name='email'>
                                            {
                                                msg => <div className='error'>{msg}</div>
                                            }
                                        </ErrorMessage>
                                    </div>

                                    <div className="form-group">
                                        <label htmlFor="password">Пароль</label>
                                        <Field type='password' name='password' className="form-control"/>
                                        <ErrorMessage name='password'>
                                            {
                                                msg => <div className='error'>{msg}</div>
                                            }
                                        </ErrorMessage>
                                    </div>

                                    <div className="form-group center">
                                        <button className="btn btn-block ">
                                            Зареєструватися
                                        </button>
                                    </div>

                                    {
                                        showErrorMessage ?
                                            <div className='error errorMessage'>{errorMessage}</div> : null
                                    }
                                </Form>
                            )}
                        </Formik>

                        <div className="form-group center">
                            <div><br/>--- або ---<br/><br/></div>

                            <div>
                                <a className="btn" href="https://api.vzhyk.com/social-auth/facebook">
                                    <span><FacebookIcon /> &nbsp;&nbsp;Вхід за допомогою Facebook</span>
                                </a>
                            </div>

                            <div><br/><br/>--- Вже є аккаунт? ---<br/><br/></div>

                            <a href='/login' className="mini-btn">
                                Увійти
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default RegisterPage;
