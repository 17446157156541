import axios from 'axios';
import authHeader from './auth-header';
import authHeaderMultipart from './auth-header-multipart';

const API_URL = process.env.REACT_APP_API_URL;

class ProjectService {
  projectsList(page, search, selectCategory, city) {
    return axios.get(API_URL + 'project/list?page=' + page + '&search=' + search + '&city=' + city + '&category=' + selectCategory, { headers: authHeader() });
  }

  projectsProgressList(page) {
    return axios.get(API_URL + 'project/list/progress?page=' + page + '&sort_column=status&sort_direction=asc', { headers: authHeader() });
  }

  tasksList(page) {
    return axios.get(API_URL + 'project/list/my?page=' + page + '&sort_column=status&sort_direction=asc', { headers: authHeader() });
  }

  getProjectMessages(projectId) {
    return axios.get(API_URL + 'chat/rooms/project-chat/' + projectId, { headers: authHeader() });
  }

  getRoomMessages(roomId) {
    return axios.get(API_URL + 'chat/rooms/messages/' + roomId, { headers: authHeader() });
  }

  getProjectRooms(projectId) {
    return axios.get(API_URL + 'chat/rooms/list/project/' + projectId, { headers: authHeader() });
  }

  message(formData, setIsUploading, setUploadingStatus) {
    return axios.post(API_URL + "chat/send", formData, {
      headers: authHeaderMultipart(),
      onUploadProgress: data => {
        setUploadingStatus(Math.round((100 * data.loaded) / data.total));
        setIsUploading(true);
      },
    });
  }

  messageAdmin(formData) {
    return axios.post(API_URL + "chat/send-admin", formData, { headers: authHeaderMultipart() });
  }

  create(title, description, duration, price, category_id, city_id) {
    return axios.post(API_URL + "project/create", {
      'name': title,
      'description': description,
      'duration': duration,
      'price': price,
      'category_id': category_id,
      'city_id': city_id,
    }, { headers: authHeader() });
  }

  applyExecutor(executorId, projectId) {
    return axios.post(API_URL + "project/apply", {
      'executor_id': executorId,
      'project_id': projectId,
    }, { headers: authHeader() });
  }

  payAndClose(projectId) {
    return axios.post(API_URL + "project/pay", {
      'project_id': projectId,
    }, { headers: authHeader() });
  }

  dispute(projectId) {
    return axios.post(API_URL + "project/dispute", {
      'project_id': projectId,
    }, { headers: authHeader() });
  }

  returnInProgress(projectId) {
    return axios.post(API_URL + "project/return", {
      'project_id': projectId,
    }, { headers: authHeader() });
  }

  toReview(projectId) {
    return axios.post(API_URL + "project/review", {
      'project_id': projectId,
    }, { headers: authHeader() });
  }

  report(projectId, reason) {
    return axios.post(API_URL + "project/report", {
      'project_id': projectId,
      'reason': projectId,
    }, { headers: authHeader() });
  }

  changeRating(projectId, rating) {
    return axios.post(API_URL + `project/${projectId}/change-rating`, {
      'rating': rating,
    }, { headers: authHeader() });
  }

  deleteTask(projectId) {
    return axios.post(API_URL + `project/remove`, {
      'project_id': projectId,
    }, { headers: authHeader() });
  }

  changeExecutorRating(ownerId, executorId, projectId, rating, comment) {
    return axios.post(API_URL + `project/${projectId}/change-executor-rating`, {
      'user_id': ownerId,
      'executor_id': executorId,
      'rating': rating,
      'comment': comment,
    }, { headers: authHeader() });
  }
}

export default new ProjectService();
