import React from 'react';
import {Redirect, useLocation} from "react-router-dom";

const SocialAuthPage = () => {
    const redirect = '/';
    const location = useLocation();
    const query = new URLSearchParams(location.search);
    const userData = query.get("data");

    localStorage.setItem("user", atob(userData));

    return <Redirect to={redirect}/>
}

export default SocialAuthPage;
